@import "_variables";

/* Image Layouts
---------------------------------------- */

h3 {
  margin-bottom: 5px;
  font-size: 20px;
  line-height: 1.5;
  @include tb {
    margin-bottom: calcTb(5);
    font-size: calcTb(20);
  }
}

h3.subtitle {
  margin-bottom: 20px;
  padding-left: 7px;
  border-left: solid 7px;
  color: #313131;
  font-size: 18px;
  border-color: #4b7ee8;
  @include tb {
    margin-bottom: calcTb(20);
    font-size: calcTb(18);
  }
}

.date {
  margin-bottom: 20px;
  text-align: right;
}

article {
  a {
    color: #4b7ee8;
  }
  p {
    margin-bottom: 25px !important;
  }
}

figcaption {
  margin: 5px auto 0;
}

.imgList {
  margin-bottom: 40px;
  text-align: center;
}

p + .imgList {
  margin-top: 35px;
}

.imgList + p {
  margin-top: -5px;
}

.imgList + .imgList {
  margin-top: -20px;
}

.imgList:last-child {
  margin-bottom: 0;
}

.imgList img,
.imgList li {
  display: inline-block;
  max-width: 530px;
  margin: 0 5px;
  vertical-align: top;
}

.imgList figcaption ul {
  margin-left: -5px;
  white-space: nowrap;
}

.imgList figcaption li {
  white-space: normal;
  text-align: left;
}

.imgList.col2 img,
.imgList.col2 li {
  width: 260px;
  @include tb {
    width: calcTb(260);
  }
}

.imgList.col2 figcaption {
  width: 530px;
  @include tb {
    width: calcTb(530);
  }
}

.imgList.col3 img,
.imgList.col3 li {
  width: 200px;
  @include tb {
    width: calcTb(200);
  }
}

.imgList.col3 figcaption {
  width: 620px;
  @include tb {
    width: calcTb(620);
  }
}

.imgList.fullSize img {
  width: auto;
  max-width: 100%;
}

.imgList figcaption.fullSize {
  width: auto;
  text-align: left;
}

/* imgBox */
.imgBox {
  margin-bottom: 40px;
}

.imgBox:last-child {
  margin-bottom: 0;
}

.imgBox > div {
  float: left;
  width: 470px;
}

.imgBox > figure {
  float: right;
  width: 220px;
}

.imgBox > figure img {
  margin-top: 20px;
}

.imgBox > figure figcaption + img {
  margin-top: 15px;
}

.imgBox > figure img:first-child {
  margin-top: 0;
}

.pageNav {
  margin-bottom: 60px;
  padding: 7px 20px;
  background: #f7f7f7;
  line-height: 1;
  text-align: right;
}
.pageNav a {
  margin-left: 20px;
  color: #4b7ee8;
  line-height: 1;
}
.pageNav .list {
  margin-right: 10px;
}
.pageNav .prev + .next {
  margin-left: 10px;
  padding-left: 10px;
  border-left: solid 1px #313131;
}

@media (max-width: 767px) {
  h3.subtitle {
    margin-bottom: 10px;
    padding-left: 4px;
    border-left-width: 4px;
    font-size: 14px;
  }
  article p {
    margin: 0 auto 15px !important;
  }
  
  /* Image Layouts
---------------------------------------- */

  .imgList {
    width: 93.75%;
    margin: 0 auto 20px;
  }
  section .imgList {
    width: 100%;
  }
  p + .imgList {
    margin-top: 20px;
  }
  .imgList + p {
    margin-top: 0;
  }
  .imgList + .imgList {
    margin-top: 0;
  }
  .imgList img,
  .imgList li {
    max-width: 100%;
    margin: 0;
  }
  .imgList figcaption ul {
    margin-left: 0;
  }

  .imgList.col2 img,
  .imgList.col2 li {
    width: 48.5%;
  }

  .imgList.col3 img,
  .imgList.col3 li {
    width: 32%;
  }

  .imgList.col4 img,
  .imgList.col4 li {
    width: 23.5%;
  }

  .imgList img + img,
  .imgList img + a,
  .imgList a + img,
  .imgList a + a,
  .imgList li + li {
    margin-left: 2%;
  }

  .imgList.col2 figcaption,
  .imgList.col3 figcaption,
  .imgList.col4 figcaption {
    width: auto;
  }

  /* imgBox */

  .imgBox {
    margin-bottom: 20px;
  }
  .imgBox > div,
  .imgBox > figure {
    float: none;
    width: auto;
  }
  .imgBox > figure {
    text-align: center;
  }
  .imgBox > div + figure,
  .imgBox > figure + div {
    margin-top: 10px;
  }

  /* pageNav
---------------------------------------- */

  .pageNav {
    margin-bottom: 30px;
  }
  section + .pageNav {
    margin-top: 0;
  }

  #searchResult .pageNav {
    margin-top: 30px;
  }
}


/* ++++++++++++++++++++++++++++++++++++++++

	Sub Page

++++++++++++++++++++++++++++++++++++++++ */

.pageNav {
  padding: 7px 20px;
  background: #f7f7f7;
  line-height: 1;
  text-align: right;
}
.pageNav a {
  margin-left: 20px;
  color: #009bc7;
  line-height: 1;
}
.pageNav .list {
  margin-right: 10px;
}
.pageNav .prev + .next {
  margin-left: 10px;
  padding-left: 10px;
  border-left: solid 1px #313131;
}

h5.subtitle {
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0;
}

.date {
  margin-bottom: 20px;
  text-align: right;
}
.date time {
  margin-right: 1em;
}

.table {
  width: 600px;
  margin-bottom: 20px;
  border: solid 1px;
  text-align: center;
  font-size: 14px;
}

.table tr {
  border-bottom: solid 1px;
}

.table tr td:first-of-type {
  width: 90px;
}
.table tr td:nth-of-type(n + 2) {
  text-align: left;
}

.table tr:last-child {
  border-bottom: none;
}

.table td {
  border-right: solid 1px;
  padding: 8px 5px;
}

.table td:last-child {
  border-right: none;
}

.table.table73 tr td:first-of-type {
  width: 300px;
}
.table.table73 tr td:nth-of-type(2) {
  width: 300px;
  text-align: center;
}

.table2 {
  width: 720px;
}

.table_style01 {
  margin: auto;
}
.table_style01 th {
  background: #b6e4ff;
  border-right: solid 1px #000;
}
.table_style01 tr td:first-of-type {
  width: 90px;
  background: #fff6b3;
}

.table_style01 + .table-comment {
  max-width: 600px;
  margin: auto;
}

#half {
  margin: 0 auto 20px;
}
#half tr td:first-of-type {
  width: 50%;
}
#half tr td:nth-of-type(n + 2) {
  text-align: center;
}
#half tr:first-child {
  background-color: #eee;
}

.half {
  margin: 0 auto 20px;
}
.half tr td:first-of-type {
  width: 50%;
}
.half tr td:nth-of-type(n + 2) {
  text-align: center;
}
.half tr:first-child {
  background-color: #eee;
}

.threePart tr td {
  width: 33%;
}

.threePart tr:first-child {
  background-color: #eee;
}

.tdLeft th {
  text-align: center !important;
  border: 1px solid #000;
  font-weight: normal;
}

.tdLeft td {
  text-align: left !important;
  padding: 15px;
}

.space1 {
  display: inline-block;
  padding-left: 1em;
  text-indent: -1em;
}

.ikb_space1 {
  display: inline-block;
  padding-left: 1em;
}

.indent li {
  text-indent: -1em;
  padding-left: 1em;
}
.center {
  text-align: center;
}
.schedule dt {
  position: absolute;
  width: 6em;
}
.schedule dd {
  padding-left: 7em;
}
.schedule.em2-5 dt {
  width: 2.5em;
}
.schedule.em2-5 dd {
  padding-left: 3em;
}
.schedule.em3 dt {
  width: 3em;
}
.schedule.em3 dd {
  padding-left: 4em;
}
.schedule.em3.no_space dd {
  padding-left: 3em;
}
.schedule.em4 dt {
  width: 4em;
}
.schedule.em4 dd {
  padding-left: 4em;
}
.schedule.em7 dt {
  width: 7em;
}
.schedule.em7 dd {
  padding-left: 8em;
}
.schedule.em9 dt {
  width: 9em;
}
.schedule.em9 dd {
  padding-left: 10em;
}
.schedule.em11 dt {
  width: 11em;
}
.schedule.em11 dd {
  padding-left: 12em;
}

.space1_3 {
  display: inline-block;
  padding-left: 3em;
  text-indent: -3em;
}

.space3 {
  padding-left: 3em;
  text-indent: -3em;
}

.space4 {
  display: inline-block;
  padding-left: 3.8em;
  text-indent: -3.8em;
}

.space5 {
  display: inline-block;
  padding-left: 5em;
  text-indent: -5em;
}

.space5_2 {
  display: inline-block;
  padding-left: 8em;
  text-indent: -5em;
}

.underline {
  text-decoration: underline;
}

.imgList.col2 img.w-auto {
  width: 150px;
  margin-right: 116px;
  margin-left: 7px;
}

.big_img img {
  max-width: 660px;
}
.imgList.col2 .region129_img {
  width: 290px;
  margin: 0 18px;
}

.red {
  color: #ff0000;
}

.align_right {
  text-align: right;
}
.align_center {
  text-align: center;
}
.imgBox.smallImg > div {
  width: 520px;
}
.imgBox.smallImg2 > div {
  width: 480px;
}
.imgBox.smallImg > figure {
  float: right;
  width: 200px;
}
img.border {
  border: solid 1px #ccc;
}

h5 {
  font-size: 15px;
}

/* ----------------------------------------
	movie
---------------------------------------- */

.movieWrap {
  width: 600px;
  margin: 40px auto;
}

/* ----------------------------------------
	last-child
---------------------------------------- */

.pageNav:last-child,
section .movieWrap:last-child {
  margin-bottom: 0;
}

/* ----------------------------------------
	clearfix
---------------------------------------- */

.clearfix:after {
	content: ".";
	display: block;
	visibility: hidden;
	clear: both;
	height: 0;
	font-size: 0;
	line-height: 0;
}

.clearfix {
	min-height: 1px;
}

* html .clearfix {
	height: 1px;
	/*\*//*/
	overflow: hidden;
	height: auto;
	/**/
}

/* ++++++++++++++++++++++++++++++++++++++++

	/company/region/ for Smart Devices

++++++++++++++++++++++++++++++++++++++++ */

@media (max-width: 767px) {
  .pconly {
    display: none;
  }

  .imgBox.smallImg2 > div {
    width: 100%;
  }

  /* ++++++++++++++++++++++++++++++++++++++++
  
    Sub Page
  
  ++++++++++++++++++++++++++++++++++++++++ */

  .pageNav {
    padding: 7px 3.125%;
  }
  .pageNav a {
    margin-left: 10px;
  }
  .pageNav .list {
    margin-right: 5px;
  }

  .table {
    width: auto;
    margin-bottom: 10%;
    border: solid 1px;
    text-align: center;
  }

  .table tr {
    border-bottom: solid 1px;
  }

  .table tr:last-child {
    border-bottom: none;
  }

  .table tr td:first-of-type {
    width: 22%;
  }
  .table tr td:nth-of-type(2) {
    text-align: left;
  }

  .table tr:last-child {
    border-bottom: none;
  }

  .table td {
    width: auto;
    border-right: solid 1px;
    padding: 8px 5px;
  }

  .table td:last-child {
    border-right: none;
  }

  .table_style01 {
    width: 100%;
  }

  .space3 {
    padding-left: 0;
    text-indent: 0;
  }

  .imgList.col2 img.w-auto {
    width: 49%;
    margin: 0;
  }
  .schedule dt {
    position: relative;
    width: auto;
  }
  .schedule dd {
    padding-left: 0;
  }
  .schedule.em2-5 dt {
    width: auto;
  }
  .schedule.em2-5 dd {
    padding-left: 0;
  }
  .schedule.em3 dt {
    width: auto;
  }
  .schedule.em3 dd {
    padding-left: 0;
    margin-bottom: 0.5em;
  }
  .schedule.em3.no_space dd {
    padding-left: 0;
  }
  .schedule.em4 dt {
    width: auto;
  }
  .schedule.em4 dd {
    padding-left: 0;
  }
  .schedule.em7 dt {
    width: auto;
  }
  .schedule.em7 dd {
    padding-left: 0;
  }
  .schedule.em9 dt {
    width: auto;
  }
  .schedule.em9 dd {
    padding-left: 0;
  }
  .schedule.em11 dt {
    width: auto;
  }
  .schedule.em11 dd {
    padding-left: 0;
  }

  .big_img img {
    max-width: 100%;
  }

  .imgList.col2 .region129_img {
    width: 48%;
    margin: 0;
  }
  .imgBox.smallImg > div {
    width: 70%;
    float: left;
  }
  .imgBox.smallImg > figure {
    float: right;
    width: 25%;
  }

  /* ----------------------------------------
    movie
  ---------------------------------------- */

  .movieWrap {
    width: 100%;
    margin: 20px auto;
  }

  /* Break Point */
}

/* ++++++++++++++++++++++++++++++++++++++++

/company/region/198/

++++++++++++++++++++++++++++++++++++++++ */

/* imgBox2 */
.imgBox2 {
  margin-bottom: 40px;
}

.imgBox2:last-child {
  margin-bottom: 0;
}

.imgBox2 > div {
  float: right;
  width: 470px;
}

.imgBox2 > figure {
  float: left;
  width: 220px;
}

.imgBox2 > figure img {
  margin-top: 20px;
}

.imgBox2 > figure figcaption + img {
  margin-top: 15px;
}

.imgBox2 > figure img:first-child {
  margin-top: 0;
}

@media (max-width: 767px) {
  /* imgBox2 */

  .imgBox2 {
    margin-bottom: 20px;
  }
  .imgBox2 > div,
  .imgBox2 > figure {
    float: none;
    width: auto;
  }
  .imgBox2 > figure {
    text-align: center;
  }
  .imgBox2 > div + figure,
  .imgBox2 > figure + div {
    margin-top: 10px;
  }

  /* Break Point */
}
