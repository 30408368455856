/* @include _breakpoints.scss */

/* break point media query */
@mixin pc {
  @media (min-width: 768px) {
    @content;
  }
}
@mixin tb {
  @media (max-width: 1100px) and (min-width: 768px) {
    @content;
  }
}
@mixin sp {
  @media (max-width: 767px) {
    @content;
  }
}

@function calcPc($pixel) {
  @return calc($pixel / 1381) * 100vw;
}
@function calcTb($pixel) {
  @return calc($pixel / 1160) * 100vw;
}
@function calcSp($pixel) {
  @return calc($pixel / 750) * 100vw;
}


