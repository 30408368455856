@import "_variables";

.l-contents {
	.img-flex {
		.col3 {
			@include sp {
				margin-top: 0;
				margin-bottom: calcSp(30);
				width: 100%;
			}	
		}
	}
}


//イクメン企業アワード
.awards-list {
	&__grid {
		margin-top: 50px;
		border: 2px solid #bb871a;
		border-radius: 14px;
		@include tb {
			margin-top: calcTb(50);
		}
		@include sp {
			margin-top: calcSp(100);
		}
		&-head {
			padding: 10px 30px;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			border-bottom: double 6px #bb871a;
			@include tb {
				padding: calcTb(10) calcTb(30);
			}
			@include sp {
				padding: 10px 20px;
			}
			h2 {
				margin: 0;
				padding: 0;
				border-left: none;
				display: flex;
				align-items: center;
				font-size: 30px;
				color: #a17524;
				@include tb {
					font-size: calcTb(30);
				}
				@include sp {
					font-size: 20px;
				}
				&::before {
					margin-right: 0.5em;
					position: static;
					content: "";
					display: block;
					width: 70px;
					height: 97px;
					background: url(../images/company/logo_awards.svg) center / 100% no-repeat;
					@include tb {
						width: calcTb(70);
						height: calcTb(97);
					}
					@include sp {
						width: 56px;
						height: 62px;
					}
				}
				span {
					padding-left: 2em;
					font-size: 12px;
					font-weight: 400;
					@include tb {
						font-size: calcTb(12);
					}
					@include tb {
						font-size: calcTb(12);
					}
				}
			}
		}
		&-item {
			padding: 10px 30px 20px 0;
			display: grid;
			grid-template-columns: 150px 1fr;
			align-items: center;
			border-bottom: 2px solid #bb871a;
			@include tb {
				padding: calcTb(10) calcTb(30) calcTb(20) 0;
				grid-template-columns: calcTb(150) 1fr;
			}
			@include sp {
				padding: 10px 20px 20px;
				display: block;
			}
			&:last-child {
				border-bottom: none;
			}
			.grid-row3 {
				grid-row: 1 / 3;
			}
			.grid-col {
				padding: 0.5em 10px 0;
				display: grid;
				grid-template-columns: 140px 1fr;
				align-items: center;
				@include tb {
					padding: 0.5em calcTb(10) 0;
					grid-template-columns: calcTb(140) 1fr;
				}
				@include sp {
					padding: 0.5em 0 0;
					grid-template-columns: 100px 1fr;
				}
				&.border-bottom {
					padding-bottom: 10px;
					border-bottom: 2px solid #f2f0f0;
					@include tb {
						padding-bottom: calcTb(10);
					}
				}
				&.grandprix {
					padding-bottom: 20px;
					margin-bottom: 10px;
					border-bottom: 2px solid #f2f0f0;
					@include tb {
						padding-bottom: calcTb(20);
						margin-bottom: calcTb(10);
					}
					@include sp {
						padding-bottom: 10px;
						margin-bottom: 5px;
					}
					dt {
						color: #a17524;
						font-weight: 600;
						font-size: 16px;
						@include tb {
							font-size: calcTb(16);
						}
						@include sp {
							font-size: 14px;
						}
					}
				}
				dt {
					font-weight: 600;
					color: #848484;
					line-height: 1.5;
					&.small {
						font-size: 12px;
						@include tb {
							font-size: calcTb(12);
						}
					}
				}
				dd {
					font-weight: 500;
				}
			}
			.grid-col2 {
				padding: 10px 10px 0;
				display: grid;
				grid-template-columns: 100px 1fr;
				align-items: center;
				@include tb {
					padding: calcTb(10) calcTb(10) 0;
					grid-template-columns: calcTb(100) 1fr;
				}
				@include sp {
					padding: 10px 0 0;
					display: block;
				}
				& > div {
					font-weight: 600;
					color: #848484;
				}
				&.grandprix {
					padding-bottom: 20px;
					margin-bottom: 10px;
					border-bottom: 2px solid #f2f0f0;
					@include tb {
						padding-bottom: calcTb(20);
						margin-bottom: calcTb(10);
					}
					@include sp {
						padding-left: 0;
						padding-bottom: 10px;
						margin-bottom: 5px;
					}
					& > div {
						color: #a17524;
						font-weight: 600;
						font-size: 16px;
						@include tb {
							font-size: calcTb(16);
						}
						@include tb {
							font-size: calcTb(16);
						}
					}
				}
				.grid-col {
					padding: 0;
					grid-template-columns: 120px 1fr;
					@include tb {
						grid-template-columns: calcTb(120) 1fr;
					}
					dt {
						font-size: 12px;
						@include tb {
							font-size: calcTb(12);
						}
					}
				}
			}
		}
	}
	&-year {
		font-size: 28px;
		font-weight: 600;
		color: #a17524;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		@include tb {
			font-size: calcTb(28);
		}
		@include sp {
			padding-left: 0;
			justify-content: left;
		}
	}
}

//くるみん
.company-kurumin {
	.box-flex {
		flex-wrap: nowrap;
		align-items: center;
		@include sp {
			display: block;
		}
	}
	.col-logo {
		width: 300px;
		flex-shrink: 0;
		text-align: center;
		@include tb {
			width: calcTb(300);
		}
		@include sp {
			width: 100%;
		}
		img {
			width: 190px;
			@include tb {
				width: calcTb(190);
			}
			@include sp {
				margin-bottom: 0;
			}
		}
	}
	.col-text {
		width: auto;
		@include sp {
			margin-top: 1em;
		}
	}
}

//イクメン企業宣言
.company-voice,
.ikuboss-voice {
	&-toggleBtn {
		margin: 40px auto;
		padding: 0 15px;
		display: flex;
		justify-content: space-between;
		border-bottom: 2px solid #005fde;
		@include tb {
			margin: calcTb(40) auto;
			padding: 0 calcTb(15);
		}
		@include sp {
			padding: 0 7px;
			margin: 0 auto calcSp(40);
			border-bottom: 1px solid #005fde;
		}
		li {
			width: calc(50% - 10px);
			@include tb {
				width: calc(50% - calcTb(10));
			}
			a,
			span {
				margin-bottom: -2px;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				height: 75px;
				font-size: 18px;
				font-weight: 600;
				color: #fff;
				border: 2px solid #005fde;
				background: #005fde;
				border-bottom: none;
				cursor: pointer;
				@include tb {
					height: calcTb(75);
					font-size: calcTb(18);
				}
				@include sp {
					height: 38px;
					font-size: 12px;
					border: 1px solid #005fde;
					border-bottom: none;
					margin-bottom: -1px;
				}
			}
			&.current {
				span {
					background: #fff;
					color: #005fde;
					cursor: auto;
				}
			}
		}
	}
	&__caution {
		margin: 20px 0 40px;
		@include tb {
			margin: calcTb(20) 0 calcTb(40);
		}
		@include sp {
			margin: 10px 0 20px;
		}
		li {
			margin-bottom: 5px;
			text-indent: -1em;
			padding-left: 1em;
			font-size: 14px;
			@include tb {
				font-size: calcTb(14);
			}
			@include sp {
				font-size: 12px;
			}
			&.title {
				margin-top: 30px;
				font-weight: 600;
				font-size: 16px;
				@include tb {
					margin-top: calcTb(30);
					font-size: calcTb(16);
				}
				@include sp {
					margin-top: 15px;
					font-size: 14px;
				}
			}
			&.red {
				color: #ff0000;
			}
		}
	}
	&__search,
	&__create {
		margin-bottom: 40px;
		position: relative;
		@include tb {
			margin-bottom: calcTb(40);
		}
		@include sp {
			margin-bottom: 20px;
		}
		.error {
			color: #ff0000;
		}
		&.conf {
			img {
				margin-bottom: 20px;
				@include tb {
					margin-bottom: calcTb(20);
				}
				@include sp {
					margin-bottom: 10px;
				}
			}
		}
	}
	&__additionalFile {
		margin-top: 1em;
		border: 1px solid #dadada;
		padding: 1em 20px;
		background-color: #fafafa;
		@include tb {
			padding: 1em calcTb(20);
		}
		@include sp {
			padding: 1em 10px;
		}
	}
	&__agreement {
		padding-top: 20px;
		border-top: 1px solid #dadada;
		font-size: 14px;
		@include tb {
			padding-top: calcTb(20);
			font-size: calcTb(14);
		}
		@include sp {
			font-size: 12px;
		}
		& > a {
			color: #005fde;
		}
		&__terms {
			margin: 40px 0;
			padding: 30px;
			border: 1px solid #dadada;
			font-size: 13px;
			@include tb {
				margin: calcTb(40) 0;
				padding: calcTb(30);
				font-size: calcTb(13);
			}
			@include sp {
				margin: 30px 0;
				padding: 20px;
				font-size: 12px;
			}
			ul {
				li {
					margin-bottom: 0.5em;
					text-indent: -1em;
					padding-left: 1em;
				}
			}
			.agree_check {
				margin: 30px auto 0;
				text-align: center;
				font-size: 16px;
				@include tb {
					margin: calcTb(30) auto 0;
					font-size: calcTb(16);
				}
				@include sp {
					margin: 20px auto 0;
					font-size: 13px;
				}
				input[type="checkbox"] {
					margin-right: 0.5em;
				}
			}
		}
	}
	&__btn {
		text-align: center;
		margin: 20px 0 70px;
		@include tb {
			margin: calcTb(20) 0 calcTb(70);
		}
		@include sp {
			margin: 10px 0 40px;
		}
	}
	&__result {
		margin: 10px 0 0 0;
		.result-item {
			margin-bottom: 30px;
			background-color: #edf2fd;
			border: 2px solid #005fde;
			border-radius: 15px;
			padding: 30px 30px 20px;
			@include tb {
				margin-bottom: calcTb(30);
				border-radius: calcTb(15);
				padding: calcTb(30) calcTb(30) calcTb(20);
			}
			@include sp {
				margin-bottom: 20px;
				border-radius: 10px;
				padding: 20px 20px 15px;
			}
			&-head {
				margin-bottom: 20px;
				display: flex;
				justify-content: space-between;
				@include tb {
					margin-bottom: calcTb(20);
				}
				@include sp {
					margin-bottom: calcSp(20);
					flex-wrap: wrap;
				}
			}
			&-logo {
				width: 200px;
				max-height: 150px;
				@include tb {
					width: calcTb(200);
					max-height: calcTb(150);
				}
				@include sp {
					margin: calcSp(40) auto;
					width: calcSp(320);
					max-height: calcSp(200);
				}
				img {
					width: 100%;
					height: 100%;
					object-fit: contain;
					object-position: top;
				}
			}
			&-images {
				margin: 50px auto 20px;
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				@include tb {
					margin: calcTb(50) auto calcTb(20);
				}
				@include sp {
					margin: calcSp(60) auto calcSp(40);
				}
				li {
					margin-bottom: 20px;
					width: calc(50% - 10px);
					max-height: 280px;
					@include tb {
						margin-bottom: calcTb(20);
						width: calc(50% - calcTb(10));
						max-height: calcTb(280);
					}
					@include sp {
						margin-bottom: calcSp(20);
						width: calc(50% - 5px);
						max-height: calcSp(320);
					}
					img {
						width: 100%;
						height: 100%;
						object-fit: contain;
						object-position: top;
					}
					&:nth-child(2n) {
						margin-left: 20px;
						@include tb {
							margin-left: calcTb(20);
						}
						@include sp {
							margin-left: 10px;
						}
					}
				}
			}
			.title {
				margin-right: 1em;
				font-size: 18px;
				font-weight: 600;
				@include tb {
					font-size: calcTb(18);
				}
				@include sp {
					margin-bottom: 0 !important;
					margin-right: 0;
					font-size: 16px;
				}
				span {
					display: block;
					font-size: 16px;
					@include tb {
						font-size: calcTb(16);
					}
					@include sp {
						font-size: 14px;
					}
				}
			}
			.display-name {
				padding-bottom: 0.5em;
				margin-bottom: 1.5em;
				border-bottom: 1px solid #005fde;
				font-size: 16px;
				font-weight: 600;
				@include tb {
					font-size: calcTb(16);
				}
				@include sp {
					margin-bottom: 1em;
					font-size: 14px;
				}
			}
			.date {
				margin-top: 2em;
				text-align: right;
				font-size: 14px;
				@include tb {
					font-size: calcTb(14);
				}
				@include sp {
					font-size: 11px;
				}
			}
		}
	}
	&__past {
		margin-bottom: 40px;
		text-align: center;
		font-size: 14px;
		@include tb {
			margin-bottom: calcTb(40);
			font-size: calcTb(14);
		}
		@include sp {
			margin-bottom: calcSp(40);
			font-size: 10px;
		}
		img {
			@include tb {
				width: calcTb(500);
			}
			@include sp {
				width: calcSp(500);
			}
		}
	}
}

//ご当地イクメン
.company-region {
	&__search {
		margin-bottom: 40px;
		padding-top: 20px;
		position: relative;
		@include tb {
			margin-bottom: calcTb(40);
			padding-top: calcTb(20);
		}
	}
	&__btn {
		text-align: center;
		margin: 25px 0 80px 0;
		@include tb {
			margin: calcTb(25) 0 calcTb(80) 0;
		}
		@include sp {
			margin: calcSp(25) 0 calcSp(80);
		}
	}
	.newsList {
		margin-top: 40px;
		padding-top: 20px;
		border-top: solid 1px #dadada;
		display: grid;
		grid-template-columns: 125px 1fr;
		font-size: 14px;
		@include tb {
			margin-top: calcTb(40);
			padding-top: calcTb(20);
			grid-template-columns: calcTb(125) 1fr;
			font-size: calcTb(14);
		}
		@include sp {
			margin-top: calcSp(40);
			padding-top: calcSp(20);
			display: block;
			font-size: 12px;
		}
		dt {
			margin-bottom: 20px;
			border-bottom: solid 1px #dadada;
			@include tb {
				margin-bottom: calcTb(20);
			}
			@include sp {
				margin-bottom: calcSp(20);
				border-bottom: none;
			}
			span {
				padding-bottom: 2px;
				display: block;
				width: 94px;
				line-height: 16px;
				margin-top: 10px;
				font-size: 12px;
				text-align: center;
				background: #c11920;
				color: #fff;
				@include tb {
					margin-top: calcTb(10);
					width: calcTb(94);
					line-height: calcTb(16);
					font-size: calcTb(12);
				}
				@include sp {
					margin-top: 0;
					font-size: 10px;
				}
			}
			&.boss {
				span {
					background: #feae07;
				}
			}
			&.both {
				span {
					&:last-child {
						margin-top: 2px;
						background: #feae07;
					}
				}
			}
		}
		dd {
			margin-bottom: 20px;
			padding: 0 0 20px;
			border-bottom: solid 1px #dadada;
			@include tb {
				margin-bottom: calcTb(20);
				padding: 0 0 calcTb(20);
			}
			@include sp {
				margin-bottom: calcSp(20);
				padding: 0 0 calcSp(20);
			}
			h3 {
				font-size: 16px;
				@include tb {
					font-size: calcTb(16);
				}
				@include sp {
					font-size: 14px;
				}
			}
		}
	}

	&-fileInputBox {
		margin-bottom: 1em;
		input[type="file"] {
			margin-bottom: 0;
		}
	}

	.submit {
		margin: 20px auto 0;
    font-size: 20px;
    line-height: 50px;
    border-radius: 5px;
    display: block;
    background: linear-gradient(#ffffff, #f5f5f5);
    width: 347px;
    height: 50px;
    border: 0;
    text-indent: 0px;
    cursor: pointer;
    color: #005fde;
    border: 1px solid #005fde;
		@include tb {
      margin-top: calcTb(20);
      font-size: calcTb(20);
      line-height: calcTb(50);
      border-radius: calcTb(5);
      width: calcTb(347);
      height: calcTb(50);
    }
    @include sp {
      margin-top: 10px;
      width: 100%;
      font-size: 18px;
      line-height: 45px;
      height: 45px;
    }
    &:hover {
      background: #005fde;
      color: #fff;
    }
	}

	@import "company/region";
}